@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";

.bt-slideshow {
    width:100%;
    height:0px;
    position:relative;
    overflow:hidden;

    .ieNotSupported {
        position:absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        padding:10pt 5pt;
        background:#efefef;
        font-weight:bold;
        font-size:1.5em;
        text-align:center;

        p {
            position:relative;
            color:#000;
            top:50%;
            transform: translateY(-70%);
            margin:0;
            padding:0 10%;

            .bticon {
                color:#000;
                display:inline-block;
                position: relative;
                bottom:-.35em;
                margin-right:.3em;
            }
        }
    }

    // Aspect ratios
    padding-bottom:56.25%; // 16:9
    &.bt-slideshow-square {
        padding-bottom:100%; // 1:1
    }
    &.bt-slideshow-4to3 {
        padding-bottom:75%; // 4:3
    }
    &.bt-slideshow-2to1 {
        padding-bottom:50%; // 2:1
    }

    a, a:hover {
        text-decoration: none;
    }

    &, * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
    }

    .bticon {
        color:#fff;
    }

    /* Full screen elements */
    .bt-slideshow-video, .bt-slideshow-playpause, .bt-slideshow-skidboard, .bt-slideshow-slide, .bt-slideshow-video iframe, .bt-slideshow-video iframe.bt-videoplaceholder, .bt-slideshow-video video {
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        width:100% !important;
        height:100% !important;
    }
    .bt-slideshow-skidboard {
        display:block;
        z-index: 101;
        pointer-events:visible;
    }
    
    .bt-slideshow-viewport>* {
        position:absolute;
        top:0;
        left:0;
        opacity:0;
        z-index:-1;
        width:100% !important;
        height:100%;
        object-fit: cover;
        background-color:#000;

        &.bt-slideshow-transitioning {
            opacity:0;
            animation: fadeIn 500ms linear; // the duration will be overwritten by JS.
            animation-iteration-count: 1;
            z-index:3;
        }
    
        @keyframes fadeIn {
            from {opacity:0;}
            to {opacity:1;}
        }

        .bticon {
            font-size:3em;
            color:#fff;
        }

        &.bt-slideshow-active {
            opacity:1;
            z-index:2;
        }
    }
    .bt-slideshow-slide {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding:0 10%;
    }
    .bt-slideshow-pause-video {
        display:none;
    }

    .bt-slideshow-center {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .bt-slideshow-top {
        position: relative;
        top: 0;
    }

    .bt-slideshow-bottom {
        transform: translateY(-100%);
        top: 100%;
        position: relative;
    }

    .bt-slideshow-video { 
        .bt-slideshow-centericon {
            position:absolute;
            z-index: 4;
            text-align:center;
            top:50%;
            left:0;
            right:0;
            line-height:3em;
            margin-top:-1.5em;
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }
    .bt-slideshow-prev, .bt-slideshow-next {
        position:absolute;
        top:50%;
        width:$grid-gutter-width * 2;
        opacity:.5;
        transform: translate(0%, -50%);
        background-color:transparent;
        transition: opacity .5s linear, background-color .5s linear;
        padding:0 15px;
        z-index: 4;

        .bticon {
            height:2em;
            line-height:2em;
            margin-top:-1em;
            font-size:2em;
        }
        
        &:hover { 
            opacity:1;
        }
    }
    .bt-slideshow-prev {
        left:0px;

        &:hover {
            background: -moz-linear-gradient(left, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(0,0,0,0.35) 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(0,0,0,0.35) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }
    }
    .bt-slideshow-next {
        right:0px;
        
        &:hover {
            background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, rgba(0,0,0,0.35) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%,rgba(0,0,0,0.35) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(0,0,0,0) 0%,rgba(0,0,0,0.35) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }
    }

    .bt-slideshow-controls {
        z-index: 5;
        position:absolute;
        left:0;
        right:0;
        color:#fff;
        font-weight:bold;
        text-align: center;
        bottom:$grid-gutter-width/2;
        opacity: 1;
        transition: opacity 500ms linear;

        .bt-slideshow-bg {
            background-color:rgba(0,0,0,.4);
            padding:3px 5px;
            border-radius:5px;
        }

        .bticon {
            position:relative;
            bottom:-0.15em;
        }
    }

    &.bt-bgvideoactive {
        .bt-slideshow-controls {
            opacity:0;
        }
    }

    .bt-slideshow-thumbnails {
        z-index: 6;
        position:absolute;
        bottom:$grid-gutter-width/2;
        left:0;
        right:0;
        text-align: left;
        overflow:hidden;
        white-space: nowrap;
        

        a{
            display: block;
            height: 100%;

            img{
                max-height: 100%;
                width: auto;
            }
        }

        // Move the controls up, if there are thumbnails
        &+.bt-slideshow-controls {
            bottom:unset !important;
            top:$grid-gutter-width/2 !important;
        }

        ul {
            padding:10px 0;
            margin:0; // this overwrites the Boostratrap 4 defaults for list spacing
            transition: margin 500ms ease-in-out;
            display: inline-block;
            width:auto;
            position:relative; // important for the JS calculations
        }
        
        li {
            display:inline-block;
            list-style: none;
            vertical-align: middle;
            height: 63px;
            margin:0 $grid-gutter-width /4;

            img {
                outline:1px solid rgba(255,255,255,.5);
                opacity:.7;
                height: 100%;
                box-shadow: 0 0 10px rgba(0,0,0,.3);
            }

            &.bt-slideshow-thumbnail-active {
                img {
                    outline:3px solid #fff;
                    box-shadow: 0 0 10px rgba(0,0,0,.7);
                    margin:0;
                    opacity:1;
                }
            }

            // Hide thumbnails and present them as bullets on mobile
            @include media-breakpoint-down(md) {
                width:.5em;
                height:.5em;
                margin:.2em;
                box-sizing: border-box;
                background: transparent;
                border-radius: 50%;
                padding:0;
                opacity:.7;
                border:1px solid #fff;

                &>* {
                    display:none;
                }
                
                &.bt-slideshow-thumbnail-active {
                    background:#fff;
                    opacity:1;
                }
            }
        }
    }

    // Hide some controls while video is playing.
    &.bt-controls-hidden {
        .bt-slideshow-prev span, .bt-slideshow-next span, .bt-slideshow-playpause .bticon, .bt-slideshow-thumbnails {
            opacity:1;
            animation: fadeOut 500ms linear forwards; // the duration will be overwritten by JS.
            animation-iteration-count: 1;
        }
        
        &.bt-video-playing {
            .bt-slideshow-pause-video {
                display:inline-block !important;
            }
        }
    
        @keyframes fadeOut {
            from {opacity:1;}
            to {opacity:0;}
        }
    }
}

html.bt-slideshow-fullscreen {
    *:not([class*="bt-slideshow-"]) {
        visibility:hidden;
    }
    .bt-slideshow.bt-slideshow-open {
        position:fixed;
        padding-bottom:initial;
        height:100%;
        left:0;
        top:0;
        right:0;
        bottom:0;
        z-index: 100;

        &, & * {
            visibility: visible !important;
        }

        .bt-slideshow-viewport>* {
            object-fit:contain !important;
        }
    }
}

// IPhone XS seems to have a curious crash with videos that change z-index, which we need for animation. 
// The temporary solution is to disable video slide animation for this mobile phone.
html.bt-slideshow-iphonexs {
    .bt-slideshow-video { 
        z-index:2 !important;
    }
}

// Make control easier to tap on mobile
@include media-breakpoint-down(sm) {
    .bt-slideshow {
        .bt-slideshow-controls {
            font-size:1.5em;
            
            &>.bt-slideshow-bg>* {
                padding:.4em;
            }
            &>.bt-slideshow-bg>a{
                font-size:1.1em;
                line-height:1;
            }
        }
        .bt-slideshow-video>a {
            font-size:1.6em;
            line-height:1.6em;
            margin-top:-.8em;
        }
        .bt-slideshow-prev, .bt-slideshow-next {
            width:5em;
            display:block;

            .bticon {
                height:2.2em;
                line-height:2.2em;
                margin-top:-1.1em;
                font-size:2.2em;
            }
        }
    }
    html.bt-slideshow-fullscreen {
        .bt-slideshow-prev, .bt-slideshow-next {
            width:20%;
            display:block;
        }
    }
}